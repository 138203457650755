import React from 'react'
import "./style.css"

export default function Footer() {
    return (
        <footer>
            <p>copyright 2020</p>
        </footer>
    )
}
